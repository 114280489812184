import React, { useEffect } from 'react'
import { Form, Modal } from 'antd'
import { compose } from 'recompose'
import { inject } from 'mobx-react'
import { useMutation } from 'react-apollo-hooks'
import _get from 'lodash/get'
import jwtDecode from 'jwt-decode'
import resetEmailMutation from '~/App/graphql/mutations/resetEmail.gql'
import { logoutUser } from '../../../auth'


const ChangeEmail = ({ match, router, history }) => {

  const resetToken = _get(match, 'params.resetToken')
  const { sub: userId, email } = jwtDecode(resetToken)
  const resetEmail = useMutation(resetEmailMutation)

  
  useEffect(() => {
    const resetMail = async () => {
      try {
        const { data } = await resetEmail({ variables: { email: email, resetToken: resetToken, userId: userId } })
        const modal = Modal.info({
          content: 'Reset Email Success',
          onOk: () => {
            logoutUser().then(() => {
              history.push('/')
            })
            modal.destroy()
          }
        })
        return data
      } catch(e) {
        const modal = Modal.warn({
          content: `${e}`,
          onOk: () => {
            router.push('/')
            modal.destroy()
          }
        })
      }
      
    }

    if (email) {
      resetMail()
    }
  //eslint-disable-next-line
  }, [resetEmail])

  return (
    <div>

    </div>
  )
}

export default compose(
  Form.create(),
  inject('router')
)(ChangeEmail)