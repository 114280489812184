import styled, { keyframes } from 'styled-components' // eslint-disable-line no-unused-vars
import { Button } from 'antd'
import { TextField } from '@material-ui/core'
import { mediaIpad, mediaIpadmini, mediaMobile } from './desktop.js'

const headerBgColor = `#FFFFFF`
const footerBgColor = `#FFFFFF`
const upcomingBg = `#eeeeee`
const btColor = `#f60`
const localeActiveBorderColor = `#f60`
// const entryFeeBntBg = `#f60`
// const entryFeeBntColor = `white`
const eventCardRegisterBtnColor = `white`
const adjustBtnBG = `#f60`
const adjustBtnColor = `white`
// const eventContentH1BgColor = `unset`
// const eventContentDetailsBgColor = `white`
// const eventContentBG = `white`
// const sideBarBgColor = `white`
// const profileBg = `white`
// const profileColor = `black`
// const registerNameColor = `white`
// const historyListBg = `white`
// const historyListColor = `black`
const inProfileBoxBg = `white`
// const EditProfilePageContentBg = `white`
// const profileInfoDataBoxColor = `white`
const eventBottomDetailTitleColor = `black`
// const dashboardBg = `#fff`
// color 1st set
const simpleBgColor = `#ffa500`
const fontColor = `black`
// color 2nd set
const fontColor2 = `black`
const simpleBgColor2 = `#ffa500`

export const AppWrapper = styled.div`
  .EventRegisterPage {
    /* display: flex;
    justify-content: center;
    min-height: fill-available; */
    background: white;
    width: 100%;
    .innerEventRegisterPage {
      /* width: 840px; */
      width: 100%;
      
      .selectRegTypeContent {
        text-align: center;
        button.registrationTypeOption {
          min-width: 300px;
          max-width: 500px;
          display: block;
          margin: auto;
          margin-bottom: 12px;
        }
      }
    }
  }
  /* .eventContent table, .eventContent th, .eventContent td {
      border: 1px solid black;
  }  */
  font-family: 'Roboto', 'Sukhumvit Set', sans-serif;
  .modlink {
    color: beige;
    text-decoration: underline;
  }
  .modlink2 {
    /* color: #1890ff; */
    text-decoration: underline;
  }
  .redUnderline {
    color: red;
    text-decoration: underline;
  }
  .headerMenu .menuItem {
    display: inline-block;
    margin: 0 13px;
  }
  @media screen and (min-width:870px) {
    .menu_icon {
      display: none;
    }
    .headerMenu {
      display: inline-block;
      float: right;
      margin-right: 37px;
      font-size: 14px;
    }
  }
  .eventBottomDetail {
    margin-top: 15px;
    li {
      color: ${fontColor2};
    }
  }
  .modLoadding{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: rgb(255,255,255,0.3);
    padding: 22px;
    border-radius: 20px;
  }
  .localeSwitcher .locale.active { 
    border-bottom: 2px solid ${localeActiveBorderColor}; 
  } 
  .registrationTopTitle {
    text-align: center;
    margin-bottom: 12px;
    font-size: 24px;
    color: black;
    font-weight: 600;
    @media screen and (max-width: 480px) {
      font-size: 18px;
      padding: 10px;
      margin-bottom: 0;
    }
  }
  .registrationCode {
    color: ${fontColor2};
    font-size: 16px;
    margin-right: 8px;
  }
  .normal_btn.orangeborder_btn {
    border: 1px solid ${simpleBgColor2};
    color: ${simpleBgColor2};
    background-color: white;
    width: 100%;
  }
  .eventContent h1 {
    margin-bottom: 8px;
    margin-top: 26px;
    padding: 0 20px;
    display: inline-block;
    color: white;
    font-size: 20px;
    background: ${simpleBgColor2};
    text-align: center;
    width: 100%;
    text-align: left;
  }
  .eventContent h1.event-name {
    background: unset;
    color: black;
    padding: 0;
  }
  .eventContent h2.content-title {
    background: unset;
    color: black;
    padding: 0;
  }
  .short_description {
    min-height: 60px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background-color: ${simpleBgColor2};
  }
  .title_line > * {
    background-color: white;
    padding: 0 20px;
    margin-left: 40px;
    color: ${simpleBgColor2};
    display: inline-block;
  }
  .countdown_box .digi {
    border: 1px solid ${simpleBgColor2};
    display: inline-block;
    color: dimgray;
    background-color: #ffffff;
    width: 35px;
    line-height: 50px;
    font-size: 22px;
    margin: 2px;
  }
  .countdown_title {
    color: ${simpleBgColor2};
    margin-top: 10px;
  }
  .modSteps.ant-steps-horizontal.ant-steps-label-horizontal {
    padding: 0px 8px;
    @media screen and (max-width: 480px) {
      display: flex;
      flex-direction: row;
      .ant-steps-item {
        display: inline-block;
        .ant-steps-item-icon {
          margin-right: 0;
        }
        .ant-steps-item-content {
          display: none;
        }
      }
    }
  }


  .summary-section {
    font-family: Sukhumvit Set;
    margin-bottom: 40px;
    padding: 0px 80px;
  }

  .summary-row {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 300;
    color: #000;
    display: flex;
    justify-content: space-between;
  }

  .sum-bold {
    font-weight: 600;
  }

  .btn-div {
    padding: 8px;
    display: flex;
    justify-content: space-around;
    margin: 20px 0px;
  }

  @media screen and (max-width: 750px) {
    .summary-section {
      width: 100%;
    }
    .mobile-padding-row {
      padding: 0px 20px;
    }
    .btn-div {
      flex-direction: column;
    }
  }
  @media screen and (min-width: 751px) {
    .summary-section {
      width: 480px;
    }
    .mobile-padding-row {
      padding-left: 80px;
      padding-right: 50px;
    }
    .btn-div {
      padding: 8px 12em;
      flex-direction: row;
    }
  }

  .totalPrice {
    font-weight: 600;
  }

  .dashedBottom {
    // border-bottom: 3px dashed rgba(0, 0, 0, 0.85);
    margin-top: 10px;
    padding-bottom: 5px;
  }
  
  .registrationPreviewDetailBox {
    display: inline-block;
    width: 100%;
    padding: 8px;
    // border-bottom: 1px solid #a6a3a8;
    .code-box-title {
      font-family: 'Roboto', 'Sukhumvit Set';
      color: black;
      font-size: 19px;
    }
    .runSubmittedBox {
      border: 2px solid gray;
      padding: 3px;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-top: 8px;
      a, button {
        margin-bottom: 8px;
      }

      .ant-tag.status {
        margin-bottom: 8px;
      }
      img.run {
        margin-right: 3px;
        border: 2px solid gray;
        border-radius: 8px;
      }
    }
  }
  .eventBottomDetailBox {
    padding: 2px;
  }
  .eventBottomDetailTitle {
    color: ${eventBottomDetailTitleColor};
    font-size: 14px;
    padding-right: 15px;
    font-family: 'Roboto', 'Sukhumvit Set';
  }
  .payButtom {
    text-align: center;
    background-color: ${simpleBgColor2};
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .choosePayment:hover {
    border: 1px solid ${simpleBgColor2};
    cursor: pointer;
  }
  .lineLoginTitle {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 14px;
    font-size: 16px;
    border-bottom: 1px solid ${simpleBgColor2};
    text-align: center;
  }
  div.simpleBgColor {
    background: ${simpleBgColor};
    color: white;
  }
  
  .ant-layout-header {
    // height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all ease;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #dfe3e8;
    background-color: ${headerBgColor};
    position: sticky;
    z-index: 8;
    top: 0px;
  }
  
  .ant-layout-content {
    background-color: ${upcomingBg};
    min-height: 100vh;
  }
  .adjustBtn {
    height: 30px;
    border-radius: 5px;
    background-color: ${adjustBtnBG} !important;
    color: ${adjustBtnColor} !important;
    min-width: 125px;
    text-align: center;
    display: inline-block;
    line-height: 30px;
    transition: 0.5s all ease;
    cursor: pointer;
  }
  
  .shape {
    min-width: 150px;
    padding: 0 20px;
    display: inline-block;
    height: 30px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    background: rgb(62, 111, 178);
    text-align: center;
  }
  .localeSwitcher .locale.active {
    border-color: ${localeActiveBorderColor};
  }
  a.login_btn {
    height: 30px;
    min-width: 125px;
    text-align: center;
    display: inline-block;
    transition: 0.5s all ease;
    cursor: pointer;
  }
  div.login_btn.login.menuItem {
    height: 30px;
    // background-color: ${localeActiveBorderColor};
    color: ${fontColor};
    min-width: 125px;
    text-align: center;
    display: inline-block;
    line-height: 30px;
    transition: 0.5s all ease;
    cursor: pointer;
  }
  .menu_icon {
    margin-right: 17px;
    margin-top: 7px;
    cursor: pointer;
    color: ${btColor};
    position: absolute;
    right: 0;
    top: 0;
  }
  .loginButtonHeader {
    text-align: center;
    font-size: 14px;
    width: 100%;
    color: ${fontColor};
    background-color: ${btColor};
    padding: 5px;
  }

  .eventCardRegisterBtn {
    background: ${btColor};
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    color: ${eventCardRegisterBtnColor};
    margin: 5px;
  }
  .closed {
    background: gray;
  }

  div.content.content-upcoming{
    text-align: center;
    max-width: 1250px;
    margin: auto;
    @media screen and (max-width: 1200px ) {
      div.eventCardBox {
        zoom: 0.87;
      }
    }
    @media screen and (max-width: 1024px ) {
      div.eventCardBox {
        zoom: 0.87;
      }
    }
    @media screen and (max-width: 1010px ) {
      div.eventCardBox {
        zoom: 0.86;
      }
    }
    @media screen and (max-width: 768px ) {
      div.eventCardBox {
        zoom: 0.655;
      }
    }
    @media screen and (max-width: 414px ) {
      div.eventCardBox {
        zoom: 1;
      }
      // div.eventCard {
      //   width: 323px !important;
      // }
    }
    @media screen and (max-width: 411px ) {
      div.eventCardBox {
        zoom: 1;
      }
    }
    @media screen and (max-width: 375px ) {
      div.eventCardBox {
        zoom: 1;
      }
      // div.eventCard {
      //   width: 300px !important;
      // }
    }
    @media screen and (max-width: 360px ) {
      div.eventCardBox {
        zoom: 90%;
      }
    }
    @media screen and (max-width: 320px ) {
      div.eventCardBox {
        zoom: 86%;
      }
      // div.eventCard {
      //   width: 280px !important;
      // }
    }
    @media screen and (max-width: 795px ) {
      div.eventCard {
        height: auto !important;
      }
      div.eventName {
        // white-space: normal !important;
      }
    }
    .eventCardImgWrapper {
      width: 360px;
      height: 200px;
      background: white;
    }
    div.eventCardBox {
      display: inline-block;
      margin: 15px;
      vertical-align: top;
      
      div.eventCard {
        // border: 1px solid white;
        // border-radius: 21px;
        text-align: left;
        width: 360px;
        height: 350px;
        min-height: 350px;
        background: white;
        overflow: hidden;
        
        div.scheduleRaceBlock {
          div.innerScheduleRaceBlock {
            // padding-left: 8px;
            // padding-bottom: 8px;
            font-size: 16px;
            margin: auto;
            div.raceDateBox {
              display: inline-block;
              // padding-left: 15px;
            }
            div.raceLocationBox {
              display: inline-block;
            }
            i {
              color: #ff6600;
              margin-right: 3px
            }
            i.vr {
              color: green;
            }
            i.location {
              color: red;
            }
          }
          div.dividerBlock {
            border-top: 1px solid rgb(255, 102, 0);
            width: 62%;
            margin: auto;
            padding: 2px;
          }
        }


        .eventRegStatusTags {
          position: relative;
          margin: -9px -7px;
          .ant-tag {
            border-radius: 11px;
            position: relative;
            bottom: 27px;
            margin-left: 8px;
          }
          .notOpenReg { 
            color: white;
            background: #ffb100;
            border-color: #ffb100;
          }
        }
        div.eventName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 23px;
          color: #1c81c4;
          text-align: left;
          margin: 4px 0px 10px 0;
          // padding: 8px;
          // text-align: center;
        }
        .eventRegStatus {
          text-align: left;
          padding: 0 0 5px 5px;
        }
        
        .eventCardImg {
          width: 360px;
          height: 200px;
          object-position: center;
          object-fit: cover;
        }
      }:hover {
        // box-shadow: 1px 2px 7px rgba(0,0,0,.2)
        // box-shadow: 0 5px 5px rgba(0,0,0,0.19), 0 5px 5px rgba(0,0,0,0.19);
        // box-shadow: rgba(255, 195, 53, 1) 1px 2px 9px 3px;
        // box-shadow: rgba(255, 195, 53, 1) 0px 0px 9px;
        touch-action: manipulation;
        cursor: pointer;
        // border-radius: 21px;
        div.eventCard {
          height: auto;
        }
        /* div.eventName {
          white-space: normal!important;
        } */
      }
    }
    @media screen and (max-width : 375px) {
      div.eventCardBox {
        /* zoom: 86%; */
        margin:15px 0;
      }
    }
    @media screen and (max-width : 720px) {
      div.eventCardBox {
        margin: 16px auto;
      }
    }
  }
  div.content.content-upcoming.finishedEvents {
    div.eventCardBox {
      margin: 7.5px;
      div.eventCard,
      div.eventCardImgWrapper,
      img.eventCardImg {
        width: 204px;
        height: 154px;
        min-height: unset;
        @media screen and (max-width : 768px) {
          width: 100px;
          height: 80px;
        }
        @media screen and (max-width : 375px) {
          width: 150px;
          height: 110px;
        }
      }
    }
  }

  .ant-layout-footer {
      background-color: ${footerBgColor};
  }
`
export const HeaderWrapper = styled.div`
  padding: 0 5%;
  div.header {
    display: flex;
    justify-content: center;
    div.headerBg {
      margin-top: 7px;
      width: 990px;
      .logo {
        height: 30px;
        margin-left: 9px;
      }
      div.dropDown {
        margin-top: 6px;
        border-bottom: 1px solid #dfe3e8;
        div.menuList {
          transition: 1s all ease;
          text-align: center;
          font-size: 15px;
          /* color: #1890ff; */
          background: white;
          position: relative;
          z-index: 1;
        }
        .menuItem {
          padding: 17px;
          display: block;
          width: 100%;
        }
        div.menuList:active {
          /* background: #1890ff; */
        }
      }
    }
  }
`

export const ProfileBox = styled.div`
  display: flex;
  justify-content: center;
  .inProfileBox {
    width: 100%;
    background-color: ${inProfileBoxBg};
    padding: 20px;
    .profileIcon {
      padding: 0 20px;
      margin-bottom: 20px;
      padding-top: 25px;
    }
    .profileName {
      font-size: 18px;
      color: ${fontColor2};
    }
    .shapeTopic {
      min-width: 150px;
      padding: 0 20px;
      display: inline-block;
      height: 30px;
      background: rgb(71, 122, 187);
      text-align: center;
    }
    .profileInfo {
      margin-left: 20px;
      .profileNameBox {
        text-align: center;
        border-bottom: 1px solid gainsboro;
        padding-bottom: 25px;
        margin-bottom: 20px;
      }
      .profileInfoTitle {
        color: ${btColor};
        font-size: 16px;
        margin-left: 10px;
      }
      .profileInfoData {
        font-size: 20px;
        color: ${fontColor2};
      }
      .profileInfo {
        margin-left: 20px;
      }
      .profileInfoDataBox {
        margin-left: 20px;
      }
    }
  }
`

export const OrangeButton = styled(Button)`
  border: none !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-color: #F9A01B !important;
  height: auto;
  ${({ type }) => (
    type === 'secondary'
      ? `
        background-color: rgba(249, 160, 27, 0.12) !important;
        color: #F9A01B !important;
        &:hover {
          background-color: #F9A01B !important;
          color: #fff !important;
        }
      `
      : `
        background-color: #F9A01B !important;
        color: #fff !important;
        &:hover {
          background-color: #ffc368 !important;
        }
      `
  )}
`

export const GreyButton = styled(Button)`
  margin-bottom: 5px;
  margin-right: 5px;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-color: #F6F6F6 !important;
  height: auto;
  &:hover {
    background-color: #ececec !important;
    color: #000 !important;
  }
`

export const EditProfilePageContent = styled.div`
  width: 100%;
  height: auto;
  padding-top: 30px;
  font-family: 'Sukhumvit Set';
  h1 {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
  }
  h2 {
    font-weight: bold;
    font-size: 20px;
  }
  legend {
    width: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

// export const EditProfilePageContent = styled.div`
// display: flex;
// justify-content: center;
// width: 100%;
// min-height: 100vh;
// background: ${dashboardBg};
//   form.ant-form.ant-form-horizontal {
//     max-width: 1000px;
//     background-color: ${EditProfilePageContentBg};
//   }
//   div.ant-form-item-label label {
//     color: black;
//   }
//   div.profileInfo{
//     .profileInfoTitle {
//       color: ${btColor};
//       font-size: 16px;
//       margin-left: 10px;
//     }

//   }

//   .ant-form legend,
//   .participantForm .ant-form legend,
//   .ant-form legend {
//       width: auto;
//   }
//   input {
//     width: 269px;
//   }
//   .ant-form-item-control-wrapper {
//     width: 269px;
//   }

//   .editProfileBox {
//     display: flex;
//     justify-content: flex-end;
//     margin: 20px;
//     .editProfileButton {
//       text-align: center;
//       background-color: ${btColor};
//       width: 60px;
//       padding-top: 10px;
//       padding-bottom: 10px;
//       padding-left: 15px;
//       padding-right: 15px;
//       color: ${fontColor};
//     }
//     .editProfileButton:hover {
//       cursor: pointer;
//     }
//   }
//   .ant-form-item-label label {
//     color: ${profileInfoDataBoxColor};
//   }
// `

export const DashBoardBox = styled.div`
  width: 100%;
  height: auto;
  padding-top: 30px;
  font-family: 'Sukhumvit Set';
  h1 {
    font-size: 28px;
    font-weight: bold;
  }
  input.ant-input {
    padding: 27px 35px;
    background-color: #F4F4F4;
    border: none;
    border-radius: 5px;
  }
  .buttonSection {
    padding: 20px 15px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .ant-btn {
      border: none;
      background-color: #F4F4F4;
    }
    button:hover, .ant-btn-primary:hover, button.ant-btn:hover {
      background-color: rgba(249, 160, 27, 0.12);
      box-shadow: none;
      color: #F9A01B;
    }
    .ant-btn-link {
      background-color: rgba(0, 0, 0, 0);
      color: rgba(0, 0, 0, 0.34);
    }
  }
  @media only screen and (max-width: 768px) {
    h1 {
      padding: 0 15px;
      margin: unset;
    }
    .ant-btn {
      border: none;
      background-color: #F4F4F4;
      margin-right: 0;
      width: calc(50% - 10px);
    }
  }
`

export const ProfileWrapper = styled.div`
  width: ${({ header }) => header ? '262px' : '100%'};
  font-family: 'Sukhumvit Set';
  ${({ header }) => header && `
    .ant-menu-item {
      margin: 0 !important;
    }
  `}
  .ant-card {
    height: 500;
    border-radius: 7px;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-avatar {
    margin: 0 auto;
  }
  .ant-menu-item {
    font-size: 16px;
  }
  .ant-menu-item-selected {
    color: #F9A01B;
    background-color: rgba(249, 160, 27, 0.08) !important;
  }
  .ant-menu-item-active {
    color: #F9A01B;
  }
  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    /* border: none */
    border-color: #F9A01B;
  }
  .ant-menu-item.ant-menu-item-selected {
    /* border-left: 5px solid #F9A01B; */
  }
  .cardHeader {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: ${({ header }) => header ? '20px 10px 0 10px' : '35px 10px 30px 10px'};
    h1 {
      margin-top: 10.5px;
      margin-bottom: 0;
      font-weight: bold;
      font-size: ${({ header }) => header && '18px'};
    }
    h2 {
      font-weight: 500;
      color: #666;
      font-size: 16px;
    }
    .headerContent {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 0 10%;
      div {
        h1 {
          font-family: 'Roboto', 'Sukhumvit Set';
          font-weight: 500;
          font-size: 20px;
          letter-spacing: 0.25px;
        }
        h3 {
          font-weight: 600;
          font-size: 20px;
          letter-spacing: 0.25px;
          margin: 0;
          &.des {
            font-weight: 200;
            font-size: 16px;
            color: #A8A8A8;
            line-height: 20px;
          }
        }
      }
    }
  }
  .cardBody {
    padding-bottom: ${({ header }) => header ? '0' : '35px'};
  }
`

export const Registration = styled.div`
  .canSubmitRunBtn {
    margin-top: 6px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-bottom: 3px;
    display: block;
    height: auto;
  }
`

export const FooterWrapper = styled.div`
`

export const FooterContainerV2 = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1c81c4;
  padding: 20px;
  ${mediaMobile.Mobile`
    flex-direction: column;
  `}
  ${mediaIpadmini.Ipadmini`
  
  `}
  ${mediaIpad.Ipad`

  `}
`

export const ImgLogoFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  ${mediaMobile.Mobile`
    justify-content: flex-start;
    width: 50%;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const AboutUSFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  ${mediaMobile.Mobile`
    justify-content: flex-start;
    width: 100%;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const ContactFooterV2 = styled.div`
  width: 30%;
  ${mediaMobile.Mobile`
    // margin-top: 20px;
    width: 100%;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const FollowFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  ${mediaMobile.Mobile`
    justify-content: flex-start;
    width: 100%;
    // margin-top: 20px;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`
export const Footer = styled.div`
  background-color: #000;
  font-size: 1.5em;
  line-height: 1;
  // padding-bottom: 2em;
  overflow: hidden;
  // background-color: #000;
  .last-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    padding: 15px 0px;
    #social {
      width: 20%;
    }
  }
  .last-line {
    color: #ffffff;
    font-size: 13px;
    font-weight: 200;
  }
  .links {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .icon {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .supportedBy {
    color: #ffffff;
    font-size: 1.5em;
    margin-bottom: 0em;
  }
  .headerText {
    font-weight: 700;
    color: #F9A11E;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .descText {
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px; 
  }
  .bottomRow {
    color: white;
    padding: 5px 15px 5px 15px;
    #footerLogo {
      width: 100%;
      margin-top: 40px;
    }
  }
  .contactUs {
    // font-family: "Roboto-Bold";
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0px;
  }
  .outsideLink {
    font-size: 13px;
    font-weight: 200;
    color: #fff;
  }
  .address {
    font-size: 13px;
    // color: #707070;
  }
  .scIcon {
    color: #fff;
    font-size: 20px;
  }
  .aboutUsSection {
    display: block;
  }
  .contactUsSection {
    margin-top: -8px;
    .contactUs {
      display: block;
    }
  }
  .followUsSection {
    line-height: 0.5;
    font-weight: 700;
    text-align: left;
    .contactUs {
      display: block;
    }
  }
  #circle1 {
    display: none;
    width: 150px;
    position: absolute;
    top: 20px;
    right: -75px;
  }
  #circle2 {
    display: none;
    width: 150px;
    position: absolute;
    left: -75px;
    bottom: -100px;
  }
  @media (max-width: 575px) {
    .last-row {
      padding: 35px 0px;
      flex-direction: column;
      #copyright {
        order: 1;
        margin-bottom: 10px;
      }
      #social {
        order: 3;
        width: 35%;
      }
      #thairun-co {
        order: 2;
        margin-bottom: 10px;
      }
    }
    .links {
      width: 100%;
      flex-direction: column;
      margin-bottom: 0;
    }
    .outsideLink {
      margin-bottom: 20px;
    }
    .contactUs {
      margin-top: 20px;
    }
    .aboutUsSection {
      display: none;
    }
    .contactUsSection {
      .contactUs {
        display: none;
      }
    }
    .followUsSection {
      line-height: 1;
      text-align: left;
      .contactUs {
        display: none;
      }
    }
    #circle1 {
      width: 150px;
      position: absolute;
      top: 20px;
      right: -140px;
    }
    #circle2 {
      width: 150px;
      position: absolute;
      left: -75px;
      bottom: -100px;
    }
    .bottomRow {
      #footerLogo {
        margin-top: 20px;
      }
    }
  }
`

export const OpenNewComingSoons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${mediaMobile.Mobile`
    flex-direction: column;
  `}
  ${mediaIpadmini.Ipadmini`
    flex-direction: column;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const OpenNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${mediaMobile.Mobile`
    width: 100%;
  `}
  ${mediaIpadmini.Ipadmini`
    width: 100%;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const ComingSoons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  ${mediaMobile.Mobile`
    width: 100%;
  `}
  ${mediaIpadmini.Ipadmini`
    width: 100%;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const eventCardStyles = styled.div`
  width: 300px;
  ${mediaMobile.Mobile`
    width: 100px;
  `}
  ${mediaIpadmini.Ipadmini`
    width: 100px;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const HeaderStyles = styled.div`
  display: flex;
  width: 1250px;
  ${mediaMobile.Mobile`
    min-width: 300px;
    width: 100%;
  `}
`

export const HeaderBoxStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaMobile.Mobile`
    display: none;
  `}
  ${mediaIpadmini.Ipadmini`
    display: none;
  `}
  ${mediaIpad.Ipad`
    display: none;
  `}
`

export const HeaderDropDownStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const HeaderIconStyles = styled.div`
  justify-content: center;
  align-items: flex-end;
  display: none;
  ${mediaMobile.Mobile`
    display: flex;
  `}
  ${mediaIpadmini.Ipadmini`
    display: flex;
  `}
  ${mediaIpad.Ipad`
    display: flex;
  `}
`

export const HeaderAFM08952BrannerStyles = styled.div`
  max-width: 1250px;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-image: url(/AFM08952-1.png);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 60px;
  ${mediaMobile.Mobile`
    padding-left: 0px;
    align-items: center;
  `}
  ${mediaIpadmini.Ipadmini`
    padding-left: 0px;
    align-items: center;
  `}
  ${mediaIpad.Ipad`
    padding-left: 0px;
    align-items: center;
  `}
`

export const HeaderBrannerStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  max-width: 1400px;
  .ant-carousel {
    .slick-slider {
      display: grid !important;
      overflow: hidden;
      background: #1d82c5;
      max-height: 378px;
      img {
        width: 100%;
      }
    }
  }

  ${mediaMobile.Mobile`
    flex-direction: column;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
  @media only screen and (max-width: 1250px) {
    .ant-carousel {
    .slick-slider {
      img {
        width: 100%;
      }
    }
  }
}
`

export const SearchLayout = styled.div`
  width: 100%;
  padding: 3% 6%;
  background: rgba(0, 0, 0, 0.54);
  position: absolute;
  z-index: 1;
  bottom: 0;
  ${mediaMobile.Mobile`
    position: relative;
    background: #333333;
  `}
  ${mediaIpadmini.Ipadmini`
    position: relative;
    background: #333333;
  `}
  ${mediaIpad.Ipad`
    position: relative;
    background: #333333;
  `}
`

export const SearchWrapper = styled.div`
  background: #fff;
  border: 1px solid #D4D4D4;
  border-radius: 12px;
  .MuiInputBase-root{
    font-family: 'Sukhumvit Set';
    font-size: 16px;
    font-weight: 400;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .MuiInput-underline:after {
    border-color: #F9A01B;
  }
  ${mediaMobile.Mobile`
    .MuiInputBase-input {
      font-size: 13px;
    }
    position: relative;
    top: -25px;
  `}
  ${mediaIpadmini.Ipadmini`
    position: relative;
    // top: -25px;
  `}
  ${mediaIpad.Ipad`
    position: relative;
    // top: -25px;
  `}
`

export const BrannerImgStyles = styled.div`
  width: 100%;
  div.titleBanner {
    h1 {
    margin: auto;
    width: 80%;
    /* border: 3px solid green; */
    position: relative;
    height: 0;
    color: white;
    top: 8px;
    font-size: 28px;
    font-weight: 300;
    .a {
      font-size: 58px;
      font-weight: 400;
      position: relative;
      top: 12px;
    }
    .b {
      color: #f4f603;
    }
    }
  }
  ${mediaMobile.Mobile`
  
  `}
  ${mediaIpadmini.Ipadmini`
  
  `}
  ${mediaIpad.Ipad`
  
  `}
  @media only screen and (max-width: 1250px) {
    div.titleBanner {
      h1 {
      margin: auto;
      width: 80%;
      /* border: 3px solid green; */
      position: relative;
      height: 0;
      color: white;
      top: 6px;
      font-size: 2.8vw;
      /* font-weight: 200; */
      .a {
        font-size: 4.8vw;
        /* font-weight: 390; */
        position: relative;
        top: 12px;
      }
      .b {
        color: #f4f603;
      }
    }
    }
  }
  @media only screen and (max-width: 414px) {
    div.titleBanner {
      h1 {
        margin: auto;
        width: 80%;
        /* border: 3px solid green; */
        position: relative;
        height: 0;
        color: white;
        top: 8px;
        font-size: 4vw;
        /* font-weight: 200; */
        .a {
          font-size: 7vw;
          /* font-weight: 390; */
          position: relative;
          top: 1px;
        }
        .b {
          color: #f4f603;
        }
      }
    }
  }
`

export const CarouselStyles = styled.div`
    // width: 874px;
    // max-width: 100%;
    // max-height: 380px;
    width: 100%;
    max-width: 874px;
  ${mediaMobile.Mobile`
    width: 47%;
    // max-width: 874px;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const ImgBrannerStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  // height: 380px;
  // background-size: cover;
  // background-image: url(/H1-1.png);
  ${mediaMobile.Mobile`
    width: 100%;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const DetailsContentStyles = styled.div`
  // display: flex;
  // justify-content: flex-style;
  // padding: 0px 0px 8px 0px;
  // ${mediaMobile.Mobile`
  //   flex-direction: column;
  // `}
  // ${mediaIpadmini.Ipadmini`
  //   flex-direction: column;
  // `}
  // ${mediaIpad.Ipad`
    
  // `}
`

export const DescriptionStyles = styled.div`
    display: flex;
  ${mediaMobile.Mobile`
    // padding-top: 20px;
  `}
  ${mediaIpadmini.Ipadmini`
    // padding-top: 15px;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

// export const PricingWrapper = styled.div`
//   img.pricing {
//     width: 100%;
//   }
// `
export const EventFormWrapper = styled.div` 
  input, .ant-select-selection.ant-select-selection--single , textarea {
    max-width: 388px;
  }
}

`
export const EventFormSuccessWrapper = styled.div` 
`

export const PaymentStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  max-width : 1250px;
  ${mediaMobile.Mobile`
    flex-direction: column-reverse;
    width : unset;
  `}
  ${mediaIpadmini.Ipadmini`
    flex-direction: column-reverse;
    width : unset;
  `}
  ${mediaIpad.Ipad`
    // flex-direction: column-reverse;
    // width : unset;
  `}
`

export const SelectPaymentMethodStyles = styled.div`
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 70%;
  padding: 11px;
  border: 1px solid #dddfe2;
  border-radius: 21px;
  min-height: 320px;
  padding: 20px 20px 5px 20px;
  ${mediaIpad.Ipad`
    width: 50%;
  `}
  ${mediaIpadmini.Ipadmini`
    width: 100%;
    min-height: auto;
  `}
  ${mediaMobile.Mobile`
    width: 100%;
    padding: 15px 15px 0px 15px;
  `}
  
`

export const PaymentAmountStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
  padding: 11px;
  border: 1px solid #dddfe2;
  border-radius: 21px;
  margin-left: 10px;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  ${mediaIpad.Ipad`
    width: 50%;
    // margin-left: unset;
    margin-bottom: 10px;
  `}
  ${mediaIpadmini.Ipadmini`
    width: 100%;
    margin-left: unset;
    margin-bottom: 10px;
  `}
  ${mediaMobile.Mobile`
    width: 100%;
    margin-left: unset;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;
  `}
`

export const PaymentAmountRegNoStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  padding: 5px 5px 0px 5px;
  ${mediaMobile.Mobile`
  
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const PaymentAmountRegNoStyles2 = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: #eee;
  padding: 20px;
  margin-top: 20px;
  p {
    margin-bottom: 5px;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
  }
  .box {
    display: flex;
  }
  .text {
    flex-grow: 1;
    color: #000;
  }
  .price {

  }
  .total {
    font-weight: 600;
  }
  ${mediaMobile.Mobile`
  
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const PaymentBottomAction = styled.div`
  text-align: center;
  padding: 20px;
  ${mediaMobile.Mobile`
    button.ant-btn.submit {
      margin: 5px;
      display: block;
    }
  `}
  ${mediaIpadmini.Ipadmini`
  `}
  ${mediaIpad.Ipad`
  `}
`

export const DiscountedStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // border-bottom: 1px solid #eeeeee;
  width: 100%;
  padding: 10px 5px 10px 5px;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 10px;
`

export const PayLaterStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px 5px 10px 5px;
  background-color: #ffefd8;
  border-radius: 5px;
  margin-top: 10px;
  /* color: #f9a01e; */
  color: red;
  font-weight: 500;
  ${mediaMobile.Mobile`
  
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const ModalPayStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: #eee;
  padding: 10px;
  border-radius: 21px;
  ${mediaMobile.Mobile`
  
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`
export const ProfileStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${mediaMobile.Mobile`
    justify-content: center;
  `}
  ${mediaIpadmini.Ipadmini`
    justify-content: center;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const ProfileBadgesStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  ${mediaMobile.Mobile`
    justify-content: center;
    align-items: center;
  `}
  ${mediaIpadmini.Ipadmini`
    justify-content: center;
    align-items: center;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const BtuProfileStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${mediaMobile.Mobile`
    justify-content: center;
    margin-bottom: 10px;
  `}
  ${mediaIpadmini.Ipadmini`
    justify-content: center;
    margin-bottom: 10px;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const ProfileRegistrationsStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${mediaMobile.Mobile`
    justify-content: center;
    margin-bottom: 10px;
  `}
  ${mediaIpadmini.Ipadmini`
    justify-content: center;
    margin-bottom: 10px;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const Racecomponents = styled.div`
    padding: 30px 50px;
  ${mediaMobile.Mobile`
    padding: 30px 10px;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`
export const RaceHeadercomponents = styled.div`
    background: #fff;
    padding: 40px 80px;
    min-height: 280px;
    border-radius: 30px;
  ${mediaMobile.Mobile`
    padding: 40px 20px;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const RaceRowcomponents = styled.div`
    display: flex;
    margin-bootom: -30px;
  ${mediaMobile.Mobile`
    flex-direction: column;
    margin-bootom: unset;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const RaceTextcomponents = styled.div`
    font-size: 40px;
    font-weight: bold;
    margin-right: 20px;
  ${mediaMobile.Mobile`
    margin-bottom: 20px;
    margin-right: unset;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const RaceSearchcomponents = styled.div`
    margin-top: 15px;
  ${mediaMobile.Mobile`
    margin-bottom: 20px;
    margin-top: unset;
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const RegistrationComponents = styled.div`
  display: flex;
  ${mediaMobile.Mobile`
    flex-direction: column;
  `}
  ${mediaIpadmini.Ipadmini`
    flex-direction: column;
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const EventCardWrapper = styled.div`
  cursor: pointer;
  .ant-card {
    border: none;
  }
  .ant-card-body {
    padding: 8px 0;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    margin: unset;
  }
  p {
    margin: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  .faIcon {
    width: 30px;
    display: inline-block;
    text-align: center;
    transform: translate(-6px, 0);
  }
  ${mediaMobile.Mobile`
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`

export const RaceInput = styled(TextField)`
  .MuiInputBase-root{
    font-size: 16px;
    font-family: 'Sukhumvit Set';
  }
  .MuiInputLabel-outlined {
    font-family: 'Sukhumvit Set';
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: #fff;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #F9A01B;
  }
  .Mui-focused fieldset  {
    border-color: #F9A01B;
  }
  .MuiOutlinedInput-input {
    padding: 28px 14px 12px 14px;
  }
  legend {
    width: 0;
  }
`
