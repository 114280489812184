import fetch from 'isomorphic-fetch'
// import config from '~/config'

const registerUser = user =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }).then(res => res.json())

const loginUser = ({ email, password }) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  }).then(res => res.json())

const logoutUser = () =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/logout`, {
    credentials: 'include'
  }).then(res => res.json())

const getAuthUri = (strategy, { redirectTo = '' } = {}) => {
  let uri = `${process.env.REACT_APP_API_AUTH_URI}/${strategy}`
  if (redirectTo) {
    uri += `?redirectTo=${redirectTo}`
  }
  return uri
}

const auth = {
  registerUser,
  loginUser,
  logoutUser,
  getAuthUri
}

// export default auth
// export default { registerUser }
export { registerUser, loginUser, logoutUser, getAuthUri }
export default auth
