import React, { Component } from 'react'
import GoogleAnalytics from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

if (JSON.parse(process.env.REACT_APP_IS_PRODUCTION)) {
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, {}, {
    autoConfig: true, // set pixel's autoConfig
    debug: false
  })

  GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID)
}
// const advancedMatching = { em: 'some@email.com' } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (JSON.parse(process.env.REACT_APP_IS_PRODUCTION)) {
      GoogleAnalytics.set({
        page,
        ...options
      })
      GoogleAnalytics.pageview(page)
      ReactPixel.pageView()
    }
  }

  const HOC = class extends Component {
    componentDidMount () { // eslint-disable-line
      // console.log('didmount')
      const page = this.props.location.pathname
      trackPage(page)
    }

    componentWillReceiveProps (nextProps) { // eslint-disable-line
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default withTracker
