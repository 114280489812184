import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _get from 'lodash/get'
import {
  Card,
  Avatar,
  Menu,
  Divider
} from 'antd'
import {
  // faCamera,
  // faChartLine
  faRunning,
  faCog,
  faFileContract,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'
import currentUserQuery from '~/App/graphql/queries/currentUser.gql'
import { ProfileWrapper } from '~/styles'
import { logoutUser } from '../../../auth'

@withRouter
@withApollo
@injectIntl
@graphql(currentUserQuery)
class Profile extends Component {
  handleLogout = () => {
    logoutUser().then(() => {
      this.props.client.resetStore()
      this.props.history.push('/')
    })
  }

  render () {
    const {
      data,
      location,
      history,
      header,
      onAuthClick,
      hideMenu,
      intl
    } = this.props
    const currentUser = _get(data, 'currentUser')
    const profile = _get(data, 'currentUser.profile')
    const email = _get(data, 'currentUser.email')
    const { pathname } = location
    const handleGoPricing = () => {
      window.scrollTo(0, 0)
      history.push('/pricing')
    }
    if (header) {
      if (currentUser) {
        return (
          <ProfileWrapper header={header}>
            <Card>
              <div className="cardHeader">
                {profile.imageUrl
                  ? <Avatar size={100} src={profile.imageUrl} />
                  : <Avatar size={100} icon="user" />
                }
                <h1>{`${profile.firstName} ${profile.lastName}`}</h1>
                <h2>{email}</h2>
                {/* <div className="headerContent">
              <div>
                <h3>{0}</h3>
                <h3 className="des">กิโลเมตรสะสม</h3>
              </div>
              <div>
                <h3>{0}</h3>
                <h3 className="des">งานวิ่งจบ</h3>
              </div>P
            </div> */}
              </div>
              <div className="cardBody">
                <Divider style={{ margin: 0 }} />
                <Menu
                  style={{ width: '100%' }}
                  selectedKeys={[pathname]}
                  defaultSelectedKeys={['/dashboard ']}
                  mode="inline"
                >
                  <Menu.Item key="/dashboard" onClick={() => history.push('/dashboard')}>
                    <FontAwesomeIcon icon={faRunning} style={{ marginRight: 10 }} />{intl.formatMessage({ id : "app.Registrations"})}
                  </Menu.Item>
                  {/* <Menu.Item key="2">
                <FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />ภาพงานวิ่ง - Photo
              </Menu.Item> */}
                  {/* <Menu.Item key="3">
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 10 }} />สถิติการวิ่ง - Statistic
              </Menu.Item> */}
                  <Divider style={{ margin: 0 }} />
                  <Menu.Item key="/profile/edit" onClick={() => history.push('/profile/edit')}>
                    <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} />{intl.formatMessage({ id : "app.account"})}
                  </Menu.Item>
                  <Menu.Item key="logout" onClick={this.handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 10 }} />
                    <FormattedMessage id="app.logout" />
                  </Menu.Item>
                </Menu>
              </div>
            </Card>
          </ProfileWrapper>
        )
      }
      return (
        <ProfileWrapper header={header}>
          <Card>
            {/* <div className="cardHeader">
              {profile.imageUrl
                ? <Avatar size={100} src={profile.imageUrl} />
                : <Avatar size={100} icon="user" />
              }
              <h1>{`${profile.firstName} ${profile.lastName}`}</h1>
              <h2>{email}</h2> */}
            {/* <div className="headerContent">
              <div>
                <h3>{0}</h3>
                <h3 className="des">กิโลเมตรสะสม</h3>
              </div>
              <div>
                <h3>{0}</h3>
                <h3 className="des">งานวิ่งจบ</h3>
              </div>P
            </div> */}
            {/* </div> */}
            <div className="cardBody">
              <Divider style={{ margin: 0 }} />
              <Menu
                style={{ width: '100%' }}
                selectedKeys={[pathname]}
                defaultSelectedKeys={['/dashboard ']}
                mode="inline"
              >
                {/* <Menu.Item key="/dashboard" onClick={() => history.push('/dashboard')}>
                  <FontAwesomeIcon icon={faRunning} style={{ marginRight: 10 }} />งานวิ่งทั้งหมด - Race
                </Menu.Item> */}
                {/* <Menu.Item key="2">
                <FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />ภาพงานวิ่ง - Photo
              </Menu.Item> */}
                {/* <Menu.Item key="3">
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 10 }} />สถิติการวิ่ง - Statistic
              </Menu.Item> */}
                <Divider style={{ margin: 0 }} />
                <Menu.Item key="onAuthClick" onClick={onAuthClick}>
                  <span onClick={hideMenu}>
                    <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: 10 }} />
                    <FormattedMessage id="app.loginOrRegister"/>
                  </span>
                </Menu.Item>
                <Menu.Item key="pricing" onClick={() => {
                  handleGoPricing()
                  hideMenu()
                }}>
                  <FontAwesomeIcon icon={faFileContract} style={{ marginRight: 10 }} />
                  <FormattedMessage id="app.pricing"/>
                </Menu.Item>
              </Menu>
            </div>
          </Card>
        </ProfileWrapper>
      )
    }

    return (
      <ProfileWrapper>
        <Card>
          <div className="cardHeader">
            {profile.imageUrl
              ? <Avatar size={100} src={profile.imageUrl} />
              : <Avatar size={100} icon="user" />
            }
            <h1>{`${profile.firstName} ${profile.lastName}`}</h1>
            <h2>{email}</h2>
          </div>
          <div className="cardBody">
            <Menu
              style={{ width: '100%' }}
              selectedKeys={[pathname]}
              defaultSelectedKeys={['/dashboard ']}
              mode="inline"
            >
              <Menu.Item key="/dashboard" onClick={() => history.push('/dashboard')}>
                <FontAwesomeIcon icon={faRunning} style={{ marginRight: 10 }} />{intl.formatMessage({ id : "app.Registrations"})}
              </Menu.Item>
              {/* <Menu.Item key="2">
                <FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />ภาพงานวิ่ง - Photo
              </Menu.Item> */}
              {/* <Menu.Item key="3">
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 10 }} />สถิติการวิ่ง - Statistic
              </Menu.Item> */}
              <Menu.Item key="/profile/edit" onClick={() => history.push('/profile/edit')}>
                <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} />{intl.formatMessage({ id : "app.account"})}
              </Menu.Item>
            </Menu>
          </div>
        </Card>
      </ProfileWrapper>
    )
  }
}

Profile.propTypes = {
  profileInfo: PropTypes.object
}

export default Profile
