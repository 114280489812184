import React from 'react'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'

import { IntlProvider } from 'react-intl'

import enMessages from '~/App/i18n/en.json'
import thMessages from '~/App/i18n/th.json'
import zhMessages from '~/App/i18n/zh.json'

const messages = {
  en: enMessages,
  th: thMessages,
  zh: zhMessages
}

const ConnectedIntlProvider = ({ i18n: { locale }, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    {children}
  </IntlProvider>
)

export default compose(
  inject('i18n'),
  observer
)(ConnectedIntlProvider)
